

import {HttpStatus, IResponse, IResult, Status} from "codebase/dist/constants/status"

export async function post_request<E>(url : string, data : any, token : string) : Promise<IResponse<E>> {

    return new Promise((resolve, reject) => {
        fetch(url, {method: "POST", body: JSON.stringify(data), headers : {
            "Authorization": "Bearer "+ token,
            "Content-Type": "application/json",
        }}).then((res) => res.json()).catch((err) => reject(err)).then((res) => resolve({data: res.data, error: null})).catch((err) => reject(err));
            
    })
}

export async function get_request<E>(url : string, token : string) : Promise<IResponse<E>> {

    return new Promise((resolve, reject) => {
        fetch(url, {method: "GET", headers : {
            "Authorization": "Bearer "+ token
        }}).then((res) => res.json()).then((res) => resolve({data: res.data, error: null})).catch((err) => reject(err));
            
    })
}

