import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams} from 'react-router-dom';
import { post_logout } from '../auth/Logout';

interface AuthContextProps {
    token : string | null,
    login : (token: string) => void,
    logout: () => void,
}

const AuthContext = createContext<AuthContextProps>(null);
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);

    function login(nt : string) {

        if (token) {
            post_logout(token);
            logout();
        }
        setToken(nt);
        localStorage.setItem('token', nt);
    }

    function logout() {
        setToken(null);
        localStorage.removeItem('token');
    }
    
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        
        const otp = searchParams.get("token");
        if (otp) {
            return login(otp)
        }

        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            return login(storedToken);
        }
    }, []);

    // Context value
    const contextValue = {
        token,
        login,
        logout,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};


export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};