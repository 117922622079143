import React, { useState } from "react";
import { post_request } from "../../api/api";
import { useAuth } from "../contexts/AuthContext";
import { Status } from "codebase/dist/constants/status";


export default function Register() {


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    const {token} = useAuth();

    async function register() {
        const {error, data} = await post_request<void>(`/api/auth/register`, {username, password, email, name}, token);
    }

        return <div>
        <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)}></input>
        <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)}></input>
        <input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)}></input>
        <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)}></input>
        <button onClick={register}>Register</button>
    </div>

}