// import React, { useContext, useEffect } from "react";
// import { post_request } from "../../api/api";
// import { HttpStatus, IResponse, IResult, Status } from "codebase/dist/constants/status";
// import { useAuth } from "../contexts/AuthContext";
// import { useLocation } from "react-router-dom";

import { useEffect } from "react"
import { redirect, useNavigate, useSearchParams } from "react-router-dom"


export default function DiscordLink() {


    const nav = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const cb = searchParams.get("redirect");
    useEffect(() => {
        setTimeout(() => {
            if (cb) {
                nav(cb)
            } else {
                nav("/home")
            }
            
        }, 1000)
    }, [])
    console.log("b")
    return <div>
        test
    </div>
}
