import React, { useState } from "react";
import { post_request } from "../../api/api";
import { useAuth } from "../contexts/AuthContext";
import { Status } from "codebase/dist/constants/status";


export default function Login() {


    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const {token, login} = useAuth();

    async function _login() {
        const {error, data} = await post_request<string>(`/api/auth/login`, {username, password}, token);
        console.log(data)
   
        login(data);
        
    }

    if (!token) {
        return <div>
        <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)}></input>
        <input type="text" placeholder="Password" onChange={(e) => setPassword(e.target.value)}></input>
        <button onClick={_login}>Login</button>
    </div>
    } else {
        return (<div>
            Already logged in
        </div>)
    }

}