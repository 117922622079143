import React, { useState, useEffect } from "react";
import LoadingBar from "../loading_bar/LoadingBar";
import "./Countdown.css"
import {Fireworks} from "./Fireworks";
interface TimeRemaining {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
}


function calculateRemainingTime(target: number): TimeRemaining {
    const diff = +target - +new Date();
    if (diff < 0) {
        return {
            days : -1,
            hours : -1,
            minutes : -1,
            seconds: -1
        }
    }
    const remaining: TimeRemaining = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 12),
        minutes: Math.floor((diff / (1000 * 60)) % 60),
        seconds: Math.floor((diff / 1000) % 60),
    }
    console.log(remaining)

    return remaining

}

export function CountdownItem({ value, label }) {


    return <div className="countdown-item">
        <span className="countdown-value">{value}</span>
        <span className="countdown-label">{label}</span>
    </div>
}

export function Countdown({ datetime }) {

    // console.log(datetime)
    const [remaining, setRemaining] = useState(null);


    useEffect(() => {
        const timer = setInterval(() => {
            setRemaining(calculateRemainingTime(datetime))
        }, 10);

        return () => clearInterval(timer);
    }, [])

    if (!remaining) {
        return <LoadingBar></LoadingBar>
    }

    const { days, hours, minutes, seconds } = remaining;

    const isDone = (days == -1 && hours == -1 && minutes == -1 && seconds == -1);

    // if (isDone) {
    //     return <Fireworks></Fireworks>
    // }
    return <div>
        <div className="countdown title">
            Nick Graduates in
        </div>
        <div className="countdown">
            <CountdownItem value={days} label={"D"}></CountdownItem>
            <CountdownItem value={hours} label={"H"}></CountdownItem>
            <CountdownItem value={minutes} label={"M"}></CountdownItem>
            <CountdownItem value={seconds} label={"S"}></CountdownItem>
        </div>

    </div>





}

