import React from 'react';
import { createRoot } from 'react-dom/client';
import {
    Routes, Route, BrowserRouter
} from "react-router-dom"
import LoadingBar from './components/loading_bar/LoadingBar';
import Homepage from './components/homepage/Homepage';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Logout from './components/auth/Logout';
// import DiscordLink from './components/auth/DiscordLink';
import Test from './components/test/Test';
import { AuthProvider } from './components/contexts/AuthContext';
import DiscordLink from './components/auth/DiscordLink';
import { Countdown } from './components/countdown/Countdown';

const container = document.getElementById('root');
const root = createRoot(container!);
const router =
    <BrowserRouter>
        <AuthProvider>
            <Routes>
                <Route path="/loading" element={<LoadingBar />} />
                {/* <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} /> */}
                <Route path="/home" element={<Homepage />}></Route>
                <Route path="/" element={<Homepage />}></Route>
                <Route path="" element={<Homepage />}></Route>


                <Route path="/auth/login" element={<Login />}></Route>
                <Route path="/auth/register" element={<Register />}></Route>
                <Route path="/auth/logout" element={<Logout />}></Route>
                <Route path="/auth/discord" element={<DiscordLink />}></Route>
                {/* <Route path="/auth/discord/link" element={<DiscordLink />}></Route> */}

                <Route path="/test" element={<Test />}></Route>

                <Route path="/graduation" element={<Countdown datetime={new Date("2024-05-11T15:00:00")}></Countdown>}></Route> 
                {/* new Date(2024, 4, 12, 8, 0, 0) 1715432400000*/  } 

                <Route path="*" element={<p>404</p>} />
            </Routes>
        </AuthProvider>
    </BrowserRouter>


root.render(
    <React.StrictMode>
        {router}
    </React.StrictMode>
)

// ReactDOM.render(
//   , document.getElementById("root")
// );


