import React, { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { post_request } from "../../api/api";

export async function post_logout(token : string) {
    console.log(token)
    return post_request("/api/auth/logout", {}, token);
}
export default function Logout() {

    const {token, logout} = useAuth();
    console.log(token)
    useEffect(() => {
        if (!token) {
            return;
        }
        post_logout(token);
        logout();
    }, [token])
    return <div> logout {token}</div>
}