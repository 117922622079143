import { ReactNode } from "react";
import "./Background.css"
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
export enum CenteredTypeEnum {
    NONE,
    HORIZONTAL,
    VERTICAL,
    BOTH
}
export interface BackgroundProps {
    img_url: string,
    centered: CenteredTypeEnum,
    children?: ReactNode
}
const ORIGINAL_BACKGROUND: string = process.env.PUBLIC_URL + "/background.jpg";
export function Background(props: BackgroundProps) {

    let className :string = "container-fluid d-flex flex-column vh-100 bg-light ";
    if (props.centered == CenteredTypeEnum.VERTICAL) {
        className += "justify-content-center "; 
    }
    if (props.centered == CenteredTypeEnum.HORIZONTAL) {
        className += "align-items-center ";
    }
    if (props.centered == CenteredTypeEnum.BOTH) {
        className += "justify-content-center "; 
        className += "align-items-center ";
    }
    

    return <div className={className} style={{ backgroundImage: `url(${props.img_url})`, backgroundSize: "100%" }}>
        {props.children}
    </div>
}

export { ORIGINAL_BACKGROUND };


export interface GradientBackgroundProps {
    children?: ReactNode
}

function getRandomHexColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }



// export function GradientBackground(props: GradientBackgroundProps) {

//     // Number of colors in the gradient
//     const numberOfColors = 4;

//     // Generate random colors
//     const colors = Array.from({ length: numberOfColors }, () => getRandomHexColor());

//     console.log(colors)//linear-gradient(${colors.join(', ')})
//     return <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100" style={{ background: `radial-gradient(circle at 20% 20%, rgba(255, 0, 0, 0.8), transparent 20%),
//     radial-gradient(circle at 80% 80%, rgba(0, 128, 128, 0.8), transparent 20%),
//     linear-gradient(to right, #000, #222)`, animation: 'moveBubbles 1s infinite linear'}}>
//         {props.children}
//     </div>
// }