
import React from "react"

import { Background, ORIGINAL_BACKGROUND, CenteredTypeEnum } from '../Background';
import './Homepage.css';

export default function Homepage() {
    return (
      <Background img_url={ORIGINAL_BACKGROUND} centered={CenteredTypeEnum.BOTH}>
            <div>  
                <div className ="nick text-center ">
                    <h1 style={{ fontSize: "220px" }}> N I C K</h1>
                </div>
                <div style={{ display: "flex", gap: "100px", justifyContent: "center" }}>
                    <button className="button" onClick={() => window.location.href = 'mailto:nrexrode02@gmail.com'}>
                        Contact
                    </button>
                    <button className="button" onClick={() => window.open("https://github.com/NickRexrode", "_blank")}>
                        Github
                    </button>
                    <button className="button" onClick={() => window.open("/resume", "_blank")}>
                        Resume
                    </button>
                </div>
            </div>
        
       // </Background>
 
    );
}