

import React from "react";
import './loadingBar1.css';
export default function LoadingBar() {
    return (
        <div>
            {/* <div class="spinner"></div> */}
            <div className="loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

    )
}
// import './loadingBar2.css';
// export default function LoadingBar() {
//     return (
//         <div class="loader">
//             <span></span>
//             <span></span>
//             <span></span>
//             <span></span>
//         </div>
//     );
// }

